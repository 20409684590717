// Theme THX777

import { Roboto, Noto_Sans_Thai } from "next/font/google";
import { alpha, createTheme } from "@mui/material/styles";
import "../theme.d";

const roboto = Roboto({
  weight: ["300", "400", "500", "700"],
  subsets: ["latin"],
  display: "swap",
});

const notoSansThai = Noto_Sans_Thai({
  weight: ["300", "400", "500", "700"],
  subsets: ["latin"],
  display: "swap",
});

const preset27 = createTheme({
  name: "preset27",
  config: {
    // แสดงปุ่มฝากถอนในกระเป๋าหรือไม่ ถ้าเป็น true จะแสดงปุ่ม ถ้าเป็น false จะไม่แสดงปุ่มในกระเป๋า
    // แต่จะแสดงนอกกระเป๋าเงิน
    showDepositAndWithdrawInWallet: false,
    // โลโก้
    logo: {
      // ขนาดโลโก้
      height: 100,
      // ขนาดโลโก้ใน AppBar
      appBarHeight: 100,
      // ระยะห่างของโลโก้ใน AppBar
      appBarSpacing: 5,
    },
  },
  palette: {
    mode: "dark",
    // สีเส้น
    divider: alpha("#e6c361", 0.4),
    // สีหลัก
    primary: {
      main: "#e6e6e6",
      contrastText: "#171203",
    },
    // สีลอง
    secondary: {
      main: "#16084d",
      contrastText: "white",
    },
    background: {
      // สีพื้นหลัง
      default: "#bf252d",
      // สี Card
      paper: "#bf252d",
    },
  },
  backgroundImage: {
    // รูปพื้นหลัง
    body: undefined,
    // ความทึบของรูปพื้นหลัง
    bodyOpacity: undefined,
  },
  mineColor: {
    // สีพื้นหลังแบบสว่าง
    "paper-soft": "#1e0d63",
    // สีพื้นหลังแบบมืด
    "paper-deep": "#080221",
  },
  shape: {
    // ขนาดขอบมน
    borderRadius: 16,
  },
  gradient: {
    // สีกระเป๋าตอนไม่ได้รับโบนัส
    100: "linear-gradient(45deg, #7623a8 30%, #a464eb 90%)",
    // สีกระเป๋าตอนรับโบนัส
    200: "linear-gradient(330deg, rgba(160,198,22,1) 32%, rgba(127,158,12,1) 100%)",
    // สี Card
    300: "#bf252d",
    // สี Appbar
    400: "#bf252d",
  },
  wheel: {
    // ขอบกงล้อ
    borderColor: "#20144a",
    segment: {
      // สีกงล้อ 1
      fillStyle1: "#ba68c8",
      // สีกงล้อ 2
      fillStyle2: "#bf252d",
    },
  },
  typography: {
    // ฟอนต์
    fontFamily: [notoSansThai.style.fontFamily, roboto.style.fontFamily].join(
      ","
    ),
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          borderRadius: "10px",
          textTransform: "capitalize",
          ...(ownerState.variant === "contained" && {
            background: preset27.palette.primary.main,
          }),
          ...(ownerState.variant === "outlined" && {
            color: preset27.palette.primary.main,
            borderColor: preset27.palette.primary.main,
          }),
        }),
      },
    },
  },
});

export default preset27;
